<template>
  <el-card shadow="hover" header="" class="x-card-title-slot">
    <div class="row no-gutters align-items-center" slot="header">
      <div class="col header-title">
        用户协议
      </div>

      <el-button type="primary" @click="addEdit" size="small">保存</el-button>
    </div>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
      <el-form-item label="名称" prop="name" style="margin-bottom: 20px">
        <el-input v-model="ruleForm.name" style="width: 300px"></el-input>
      </el-form-item>

      <el-form-item label="内容" prop="content" style="width: 70vw;" class="ue-wrapper">
        <UE ref="ue" :defaultMsg="ruleForm.content"></UE>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import { UE } from "@/components";
import * as API_shop from "@/api/shop";

export default {
  components: {
    [UE.name]: UE,
  },
  name: "person",
  data () {
    return {
      rules: {
        name: {
          required: true,
          message: "请输入名称！",
          trigger: "blur"
        },
      },
      ruleForm: {
        content: '',
        name: "",
        type: 1
      },
    };
  },
  mounted () {
    this.getDetail();
  },

  created () {
  },
  computed: {},
  methods: {
    getDetail () {
      API_shop.getShopAgree({
        type: 1,
      }).then((res) => {
        if (res) this.ruleForm = res;
      });
    },
    addEdit () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          const params = {
            ...this.ruleForm,
          };
          params.content = this.$refs.ue.getUEContent();
          API_shop.editAgree(params).then((res) => {
            this.$message.success("保存成功！");
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .ue-wrapper {
  .edui-default * {
    line-height: 1;
  }

  .edui-editor-iframeholder {
    height: 500px !important;
  }
}
</style>
